<template>
  <v-card color="white" class="pa-8">
    <v-card-text class="px-0">
      <div>
        <div class="bsat__title">{{ $t("title") }}</div>
        <!-- ************************** OVERVIEW ***************************** -->
        <div>
          <i18n
            path="overview.paragraph.text"
            tag="div"
            class="bsat__paragraph"
          >
            <template #link1>
              <a href="/new?phase=3&step=2">{{
                $t("overview.paragraph.link1")
              }}</a>
            </template>
          </i18n>
        </div>

        <div class="bsat__paragraph">
          {{ $t("overview.paragraph2") }}
        </div>

        <!-- ******************** SUMMARY *********************** -->

        <!-- <div class="bsat__subtitle mb-4">{{ $t("summary.title") }}</div>

        <ul class="bsat__paragraph">
          <li v-for="(item, i) in summaryUnorderedList" :key="i" class="my-1">
            <div>
              <span>{{ item.title }}</span>
              <ul class="bsat__paragraph" v-if="item.children">
                <li
                  v-for="(item, i) in getSummaryChildren(item.children)"
                  :key="i"
                  class="my-1"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </li>
        </ul> -->

        <!-- ************************ IMPLEMENTATION RECOMMENDATIONS ********************************* -->
        <section id="implementationRecommendations">
          <div class="bsat__subtitle mb-4">
            <strong>{{ $t("implementationRecommendations.title") }}</strong>
          </div>

          <ReadMore :intro="$t('implementationRecommendationReadMore')">
            <i18n
              path="implementationRecommendations.task.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.task.title")
                }}</strong>
              </template>
            </i18n>

            <i18n
              path="implementationRecommendations.taskLeader.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.taskLeader.title")
                }}</strong>
              </template>
            </i18n>

            <div class="bsat__paragraph mb-4">
              <strong>{{
                $t("implementationRecommendations.proposedMethods.title")
              }}</strong>
            </div>

            <div>
              <ul>
                <li
                  class="bsat__paragraph mb-4"
                  v-for="(item, i) in proposedMethods"
                  :key="i"
                >
                  <div>
                    <span>{{ item.title }}</span>
                    <ul v-if="item.children">
                      <li
                        class="bsat__paragraph mb-2"
                        v-for="(child, i) in getChildren(item)"
                        :key="i"
                      >
                        {{ child }}
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </ReadMore>
        </section>
        <!-- ************************* RECOMMENDED RESOURCES ******************************* -->

        <section id="recommendedResources">
          <div class="bsat__subtitle mb-4">
            {{ $t("recomendedResources.title") }}
          </div>

          <!-- <div class="bsat__paragraph mt-n3 mb-6">
            {{ $t("recommendedResourcesIntro") }}
          </div> -->

          <ul class="ml-0 ml-sm-4 bsat__ul">
            <li>
              <div>
                <i18n
                  path="recomendedResources.paragraph.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #link1>
                    <a href="/new?phase=3&step=2">{{
                      $t("recomendedResources.paragraph.link1")
                    }}</a>
                  </template>
                </i18n>
              </div>
            </li>

            <li>
              <div>
                <i18n
                  path="recomendedResources.catalogue.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #title>
                    <strong>{{
                      $t("recomendedResources.catalogue.title")
                    }}</strong>
                  </template>
                </i18n>
                <a
                  :href="catalogueLink"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <v-btn
                    outlined
                    width="150"
                    height="26"
                    class="bsat__btn"
                    color="dark"
                    >{{ $t("visit") }}</v-btn
                  >
                </a>
              </div>
            </li>

            <li>
              <div>
                <i18n
                  path="recomendedResources.bestPractises.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #title>
                    <strong>{{
                      $t("recomendedResources.bestPractises.title")
                    }}</strong>
                  </template>
                </i18n>
                <router-link
                  to="/best-practice-examples-of-bio-based-solutions-with-replicability-potential"
                >
                  <v-btn
                    outlined
                    width="150"
                    height="26"
                    class="bsat__btn"
                    dark
                    color="dark"
                    >{{ $t("visit") }}</v-btn
                  >
                </router-link>
              </div>
            </li>

            <li>
              <div>
                <i18n
                  path="recomendedResources.training.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #link>
                    <strong>{{
                      $t("recomendedResources.training.link")
                    }}</strong>
                  </template>
                </i18n>
                <v-btn
                  outlined
                  width="150"
                  height="26"
                  class="bsat__btn"
                  color="dark"
                  to="/training-in-bioeconomy-sectors"
                  >{{ $t("visit") }}</v-btn
                >
              </div>
            </li>

            <!-- <li>
              <div class="bsat__paragraph mb-4">
                <strong>{{ $t("recomendedResources.guidelines") }}</strong>
              </div>
              <a :href="guidelinesFile" target="_blank" download>
                <v-btn
                  depressed
                  width="150"
                  height="26"
                  class="bsat__btn"
                  dark
                  color="dark"
                  >{{ $t("download") }}</v-btn
                >
              </a>
            </li> -->
          </ul>
        </section>
        <i18n
          class="mt-8"
          tag="div"
          path="downloadAll.text"
          style="font-family: Montserrat"
        >
          <template #link>
            <a class="dark-text mt-8" :href="zip" target="_blank" download>
              {{ $t("downloadAll.link") }}
            </a>
          </template>
        </i18n>
      </div>
    </v-card-text>
  </v-card>
</template>

<i18n>
{
  "en": {
    "implementationRecommendations": {
      "proposedMethods": {
        "title": "Proposed methods",
        "unorderedList": [
          {
            "title": "Feedback rounds (as many as necessary) with decision-level of Interministerial/interdepartmental group and advisory group. "
          },
          {
            "children": [
              "Sharing drafted document and asking for comments",
              "(sectorial) Focus groups",
              "Discussion/workshop with a selected group of experts (Advisory group)",
              "Thematic working groups"
            ],
            "title": "Ask the feedback of stakeholders engaged through the participatory approach in the strategy and now roadmap development. "
          }
        ]
      },
      "task": {
        "text": "{title}: Derive specific goals, its specific actions and responsible for implementation.",
        "title": "Task"
      },
      "taskLeader": {
        "text": "{title}: Strategy development working group.  Key stakeholders engaged through the participatory process",
        "title": "Task leader"
      },
      "title": "Implementation Recommendations"
    },
    "overview": {
      "paragraph": {
        "link1": "Step 2, Phase 3",
        "text": "Based on the defined priority areas of the strategy ({link1}) and the main goal in them defined, more specific and measurable goals should be detailed. The initial draft of specific goals can be carried out by the strategy development working group (Interministerial/interdepartmental group, advisory group including selected members of the RBH and the group in charge of participatory process). Later on, these can be agreed with other engaged stakeholders in case of relevant conflicting goals. "
      },
      "paragraph2": "For each of these specific goals in each corresponding priority area, attainable actions should be also drafted making sure to assign to each one a responsible institution for its implementation. Clarifying as well how to assess and monitor a good implementation."
    },
    "recomendedResources": {
      "bestPractises": {
        "text": "{title}  Consult a selection of bio-based solutions from the POWER4BIO catalogue, which thoroughly describe their potential for replicability in rural areas",
        "title": "Best practice examples: "
      },
      "catalogue": {
        "text": "{title} Get inspired by the POWER4BIO catalogue of bio-based solutions. In total, the catalogue contains 32 biorefineries, selected by their high market uptake potential and high technical readiness level (TRL) for bioenergy production, biomaterials, biochemicals as well as for food and feed. All factsheets include a short description and several characteristics of the solution, as well as information regarding the suitability of biomass feedstock for production of the target product, benefits of the solution related to the value chain and specific constraints for implementation. The selected content topics of the factsheets are General, Feedstock, Technology, Products, Environment and Impacts.",
        "title": "Catalogue of bio-based solutions: "
      },
      "guidelines": "Guidelines for cross-regional cooperation",
      "paragraph": {
        "link1": "Step 2, Phase 3",
        "text": "Determined priority areas and their main goals ({link1}) "
      },
      "title": "Recommended resources: ",
      "training": {
        "link": "Training in Bioeconomy sectors:",
        "text": "{link}  Find suitable training topics. POWER4BIO training materials can be useful as elaborated analysis and description to use for training purposes. They include webinars developed in the 4 bio applications (bioenergy. Food&feed, biochemicals and biomaterials), dealing with a wide range of key aspects."
      }
    },
    "title": "Step 1: Derive specific goals within priority areas, specific actions and responsible for implementation",
    "downloadAll": {
			"link": "click here",
			"text": "*To download all the materials of Phase 3 and Phase 4 as a zip file please {link}"
		}
  }
}
</i18n>

<script>
import { EventBus } from "@/utils/EventBus";
import ReadMore from "@/components/ReadMore";
export default {
  name: "Phase4Step1",
  components: {
    ReadMore
  },
  data() {
    return {
      bestPractisesFile: require("@/assets/files/1_3_2_2_Best_practice_examples_of_bio_based_solutions_with_replicability_potential.docx")
        .default,
      // TODO: 1.4.1
      guidelinesFile: "",
      catalogueLink: "https://www.bio-based-solutions.eu/#/",
      bestPractisesLink: "",
      zip: require("@/assets/files/Phase3_Phase4_common_resources.zip").default
    };
  },
  computed: {
    proposedMethods() {
      return Object.values(
        this.$t("implementationRecommendations.proposedMethods.unorderedList")
      );
    },
    summaryUnorderedList() {
      return Object.values(this.$t("summary.unorderedList"));
    }
  },
  methods: {
    goToTab(tab) {
      EventBus.$emit("goToTab", tab);
    },
    getSummaryChildren(obj) {
      return Object.values(obj);
    },
    getChildren(item) {
      return Object.values(item.children);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/main";

.list {
  $font-family: $font-family;
  // font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;

  &-link {
    color: $light_green !important;
  }
}
</style>
